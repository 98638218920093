import React, { useState } from 'react';
import { CoverContainer } from './style';
import EmailValidator from 'email-validator';
import { Header, Image, Button, Modal, Checkbox, Form, Message } from 'semantic-ui-react';
import { SocialMedia } from '../socialMedia';
import health from '../../media/health.jpg';

export const Cover = () => {

  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [professional, setProfessional] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleChange = (_, { name, value }) => {

    // set values
    if (name === 'email') setEmail(value);
    else if (name === 'fullName') setFullName(value);
    else if (name === 'professional') setProfessional(value);

    // validate fields
    if (
      ((email.length > 3 && fullName.length > 3) ||
      (name === 'email' && value.length > 3 && fullName.length > 3) ||
      (name === 'fullName' && value.length > 3 && email.length > 3)) &&
      (!!EmailValidator.validate(email) || !!EmailValidator.validate(value))
    ) setFormDisabled(false);
    else setFormDisabled(true);
  }

  const handleSubmit = () => {

    // loading
    setFormLoading(true);

    // Zapier + MailChimp Webhook
    let base = 'https://hooks.zapier.com/hooks/catch/8751515/oqyzyt3/silent/';
    const fullUrl = `${base}?email=${email}&name=${fullName}&professional=${professional}`;

    // POST Request
    fetch(fullUrl)
    .then(res => {

      if (res.ok) {

        // success
        setFormLoading(false);
        setEmail('');
        setFullName('');
        setProfessional(false);
        setFormError(false);
        setFormSuccess(true);

      } else {

        // error
        setFormLoading(false);
        setFormSuccess(false);
        setFormError(true);
      }
    });
  }

  return (
    <CoverContainer>

      <section className='callToAction'>

        <Image circular src={health} />

        <Header as='h1' textAlign='left'>
          <Header.Content>Get Ready for The Future of Health & Wellness</Header.Content>
        </Header>

        <Header as='h3' textAlign='left' color='red'>
          <Header.Content>Official HPB Website Launching 2022</Header.Content>
        </Header>

        <Button size='huge' fluid primary onClick={() => window.location = "mailto:jon@HealthPalmBeach.com"}>Get In Touch</Button>
        <Button size='huge' fluid color="red" onClick={() => window.open("https://www.facebook.com/events/555658931959760?__mref=mb", "_blank")}>Health Palm Beach Event</Button>
      </section>

      <SocialMedia />
    </CoverContainer>
  );
};