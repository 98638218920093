import styled from 'styled-components';

export const BlogPostsContainer = styled.section`
  background: whitesmoke;
  height: 100vh;
  min-height: 25%;
  overflow: auto;
  padding: 80px 40px 0 40px;
  width: 100%;

  .offers {
    margin-top: 30px;
  }

  .ui.header {
    margin-bottom: 25px;
  }

  .sub.header {
    letter-spacing: 0.25px;
    margin-top: 10px !important;
  }

  .ui.card {
    width: 100%;
  }

  .cards {
    margin: 50px 0;
  }

  .disclaimer {
    margin-top: 50px;
  }

  @media (max-width: 600px) {

    .card {
      width: 100% !important;
    }
  }

  @media (max-width: 1000px) {
    height: auto;
    overflow: visible;
  }
`;