import styled from 'styled-components';

export const CoverContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  min-height: 750px;
  padding: 40px 40px 0 40px;
  width: 100%;

  img {
    border-radius: 0 !important;
    height: 175px !important;
    object-fit: contain !important;
    width: 100%;
  }

  .callToAction {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .callToAction button {
    margin: 10px 5px !important;
  }

  @media (max-width: 1000px) {
    height: 100%;
  }
`;