import React from 'react';
import { ComingSoonContainer } from './style';
import { Cover } from '../../components/cover';
import { BlogPosts } from '../../components/blogPosts';

export const ComingSoon = () => {
  return (
    <ComingSoonContainer>
      <Cover />
      <BlogPosts />
    </ ComingSoonContainer>
  );
};