import styled from 'styled-components';

export const SocialMediaContainer = styled.section`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  width: 100%;
  position: relative;
  bottom: 0;

  button {
    box-shadow: none !important;
    margin-right: 7px !important;
  }
`;