import React from 'react';
import { SocialMediaContainer } from './style';
import { Button } from 'semantic-ui-react';

export const SocialMedia = () => {
  return (
    <SocialMediaContainer>
      <Button basic circular size='huge' icon='facebook f' onClick={()=> window.open("https://www.facebook.com/HealthPalmBeach", "_blank")} />
      <Button basic circular size='huge' icon='instagram' onClick={()=> window.open("https://www.instagram.com/healthpalmbeach", "_blank")} />
      <Button basic circular size='huge' icon='twitter' onClick={()=> window.open("https://twitter.com/health_beach", "_blank")} />
      <Button basic circular size='huge' icon='linkedin' onClick={()=> window.open("https://www.linkedin.com/company/health-palm-beach", "_blank")} />
    </SocialMediaContainer>
  );
};