import React from 'react';
import { BlogPostsContainer } from './style';
import { Header, Image, Label, Segment, Grid } from 'semantic-ui-react';
import articles from '../../media/articles.jpg';
import checkup from '../../media/checkup.jpg';
import pharmacy from '../../media/pharmacy.jpg';
import profile from '../../media/profile.jpg';
import teleHealth from '../../media/teleHealth.jpg';
import selfImprovement from '../../media/selfImprovement.jpg';

export const BlogPosts = () => {
  return (
    <BlogPostsContainer>

      <Header as='h1'>
        Welcome to Health Palm Beach
      </Header>

      <Grid stackable columns={2} className='offers'>
        <Grid.Column>
          <Segment raised>
            <Label as='div' color='teal' ribbon>
              Create A Professional Profile
            </Label>
            <Image src={profile} />
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment raised>
            <Label as='div' color='blue' ribbon>
              Sell Products Online
            </Label>
            <Image src={pharmacy} />
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment raised>
            <Label as='div' color='red' ribbon>
              Post Informative Articles
            </Label>
            <Image src={articles} />
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment raised>
            <Label as='div' color='yellow' ribbon>
              Event Advertisement
            </Label>
            <Image src={checkup} />
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment raised>
            <Label as='div' color='orange' ribbon>
              Schedule Telehealth Appointments
            </Label>
            <Image src={teleHealth} />
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment raised>
            <Label as='div' color='violet' ribbon>
              Expand Your Market
            </Label>
            <Image src={selfImprovement} />
          </Segment>
        </Grid.Column>
      </Grid>

      
      <Header as='h1' textAlign='center' className='disclaimer'>
        <Header.Subheader>
          © All rights reserved 2021
        </Header.Subheader>
      </Header>

    </BlogPostsContainer>
  );
};